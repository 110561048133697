import { surveyLocalization } from "survey-core";

export var macedonianSurveyStrings = {
  pagePrevText: "Претходна",
  pageNextText: "Следно",
  completeText: "Заврши",
  previewText: "Преглед",
  editText: "Уредување",
  startSurveyText: "Започнете",
  otherItemText: "Друго (опиши)",
  noneItemText: "Ништо",
  selectAllItemText: "Селектирај се",
  progressText: "Страница {0} од {1}",
  panelDynamicProgressText: "Сними {0} од {1}",
  questionsProgressText: "Одговорени на {0} / {1} прашања",
  emptySurvey: "Нема видлива страница или прашање во истражувањето.",
  completingSurvey: "Ви благодариме што го завршивте истражувањето!",
  completingSurveyBefore:
    "Нашите записи покажуваат дека веќе сте го завршиле ова истражување.",
  loadingSurvey: "Анкетата се вчитува ...",
  placeholder: "Изберете ...",
  value: "вредност",
  requiredError: "Ве молам, одговорете на прашањето.",
  requiredErrorInPanel: "Ве молам, одговорете барем на едно прашање.",
  requiredInAllRowsError: "Ве молиме, одговорете на прашања во сите редови.",
  numericError: "Вредноста треба да биде нумеричка.",
  minError: "Вредноста не треба да биде помала од {0}",
  maxError: "Вредноста не треба да биде поголема од {0}",
  textMinLength: "Внесете најмалку {0} знак/ци.",
  textMaxLength: "Внесете не повеќе од {0} знак/ци.",
  textMinMaxLength: "Внесете најмалку {0} и не повеќе од {1} знаци.",
  minRowCountError: "Пополнете најмалку {0} ред(ови).",
  minSelectError: "Ве молиме изберете најмалку {0} варијанта(и).",
  maxSelectError: "Изберете не повеќе од {0} варијанта(и).",
  numericMinMax:
    "'{0}' треба да биде најмалку {1} и најмногу {2}",
  numericMin: "'{0}' треба да биде најмалку {1}",
  numericMax: "'{0}' треба да биде најмногу {1}",
  invalidEmail: "Ве молиме внесете валидна е-маил адреса.",
  invalidExpression: "Изразот: {0} треба да се врати 'true'.",
  urlRequestError: "Барањето врати грешка '{0}'. {1} ",
  urlGetChoicesError:
    "Барањето врати празни податоци или својството 'path' е неточно",
  exceedMaxSize: "Големината на датотеката не треба да надминува {0}.",
  otherRequiredError: "Внесете ја другата вредност.",
  uploadingFile:
    "Вашата датотека се поставува. Ве молиме почекајте неколку секунди и обидете се повторно.",
  loadingFile: "Се вчитува ...",
  chooseFile: "Изберете датотека (и) ...",
  fileDragAreaPlaceholder: "Пуштете датотека овде или кликнете на копчето подолу за да ја вчитате датотеката.",
  noFileChosen: "Не се избрани датотеки",
  confirmDelete: "Дали сакате да го избришете записот?",
  keyDuplicationError: "Оваа вредност треба да биде единствена.",
  addColumn: "Додај колона",
  addRow: "Додади ред",
  removeRow: "Отстрани",
  emptyRowsText: "Нема редови.",
  addPanel: "Додади ново",
  removePanel: "Отстрани",
  choices_Item: "ставка",
  matrix_column: "Колона",
  matrix_row: "Ред",
  savingData: "Резултатите се зачувуваат на серверот ...",
  savingDataError: "Настана грешка и не можевме да ги зачуваме резултатите.",
  savingDataSuccess: "Резултатите беа успешно зачувани!",
  saveAgainButton: "Обиди се повторно",
  timerMin: "мин",
  timerSec: "сек",
  timerSpentAll: "Поминавте {0} на оваа страница и вкупно {1}.",
  timerSpentPage: "Поминавте {0} на оваа страница.",
  timerSpentSurvey: "Вие потрошивте вкупно {0}.",
  timerLimitAll:
    "Поминавте {0} од {1} на оваа страница и {2} од {3} вкупно.",
  timerLimitPage: "Поминавте {0} од {1} на оваа страница.",
  timerLimitSurvey: "Вие потрошивте вкупно {0} од {1}.",
  clearCaption: "Да расчисти",
  chooseFileCaption: "Изберете датотека",
  removeFileCaption: "Отстранете ја оваа датотека",
  booleanCheckedLabel: "Да",
  booleanUncheckedLabel: "Не",
  confirmRemoveFile: "Дали сте сигурни дека сакате да ја отстраните оваа датотека: {0}?",
  confirmRemoveAllFiles: "Дали сте сигурни дека сакате да ги отстраните сите датотеки?",
  questionTitlePatternText: "Наслов на прашањето",
  modalCancelButtonText: "Откажи",
  modalApplyButtonText: "Аплицирај",
};

surveyLocalization.locales["mk"] = macedonianSurveyStrings;
surveyLocalization.localeNames["mk"] = "Македонски";
