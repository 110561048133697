import { surveyLocalization } from "survey-core";

export var tajikSurveyStrings = {
  pagePrevText: "Бозгашт",
  pageNextText: "Оянда",
  completeText: "Иҷро шуд",
  startSurveyText: "Оғоз",
  otherItemText: "Дигар (лутфан тавсиф кунед)",
  noneItemText: "Не",
  selectAllItemText: "Ҳамаро интихоб кардан",
  progressText: "Саҳифаи {0} аз {1}",
  emptySurvey: "Ягон савол вуҷуд надорад.",
  completingSurvey: "Ташаккур барои пур кардани саволнома!",
  completingSurveyBefore:
    "Шумо аллакай ин пурсишро анҷом додаед.",
  loadingSurvey: "Боргирӣ аз сервер...",
  placeholder: "Интихоб кардан...",
  value: "қиммат",
  requiredError: "Илтимос, ба савол ҷавоб диҳед.",
  requiredErrorInPanel: "Илтимос, ақалан ба як савол ҷавоб диҳед.",
  requiredInAllRowsError: "Илтимос, ба ҳамаи саволҳо дар ҳамаи сатрҳо ҷавоб диҳед.",
  numericError: "Ҷавоб бояд рақам бошад.",
  textMinLength: "Илтимос, аз {0} зиёдтар рамз ворид кунед.",
  textMaxLength: "Илтимос, аз {0} камтар рамз ворид кунед.",
  textMinMaxLength: "Илтимос, аз {0} зиёдтар ва аз {1} камтар рамз ворид кунед.",
  minRowCountError: "Илтимос, на камтар аз {0} сатр пур кунед.",
  minSelectError: "Илтимос, ақалан {0} вариант интихоб кунед.",
  maxSelectError: "Илтимос, на зиёдтар аз {0} вариант интихоб кунед.",
  numericMinMax:
    "'{0}' бояд на кам аз {1} ва на бисёр аз {2} бошад",
  numericMin: "'{0}' бояд на кам аз {1} бошад",
  numericMax: "'{0}' бояд на зиёд аз {1} бошад",
  invalidEmail: "Илтимос, почтаи электронии воқеиро ворид кунед.",
  invalidExpression: "Ифодаи {0} бояд 'true' баргардонад.",
  urlRequestError: "Дархост хатогӣ бозгардонд '{0}'. {1}",
  urlGetChoicesError:
    "Ҷавоб ба дархост холӣ омад ё хосияти 'path' нодуруст муайян карда шудааст",
  exceedMaxSize: "Андозаи файл бояд на калон аз {0} бошад.",
  otherRequiredError: "Илтимос, ба майдони 'Дигар' додаҳоро ворид кунед",
  uploadingFile:
    "Файли шумо бор шуда истодааст. Якчанд сония интизор шавед ва бори дигар кӯшиш кунед.",
  loadingFile: "Боркунӣ...",
  chooseFile: "Файл(ҳо)-ро интихоб кунед...",
  confirmDelete: "Шумо мутмаин ҳастед, ки мехоҳед воридро тоза кунед?",
  keyDuplicationError: "Ин арзиш бояд беназир бошад.",
  addColumn: "Иловаи сутун",
  addRow: "Иловаи сатр",
  removeRow: "Нест кардан",
  addPanel: "Илова кардан",
  removePanel: "Нест кардан",
  choices_Item: "Вариант",
  matrix_column: "Сутун",
  matrix_row: "Сатр",
  savingData: "Натиҷа ба сервер сабт шуда истодаанд...",
  savingDataError: "Хатогӣ ба амал омад, натиҷа сабт нашуд.",
  savingDataSuccess: "Натиҷа бомуваффакият сабт шуд!",
  saveAgainButton: "Бори дигар кӯшиш карданд",
  timerMin: "дақ",
  timerSec: "сон",
  timerSpentAll: "Шумо {0} дар ин саҳифа ва {1} дар умум сарф кардед.",
  timerSpentPage: "Шумо {0} дар ин саҳифа сарф кардед.",
  timerSpentSurvey: "Шумо {0} дар ин тест сарф намудед.",
  timerLimitAll:
    "Шумо {0} аз {1} дар ин саҳифа ва {2} аз {3} дар умум сарф кардед дар дохили ин тест.",
  timerLimitPage: "Шумо {0} аз {1} дар ин саҳифа сарф кардед.",
  timerLimitSurvey: "Шумо {0} аз {1} дар ҳамаи тест сарф кардед.",
  clearCaption: "Тоза кардан",
  removeFileCaption: "Файлро нест кардан"
};

surveyLocalization.locales["tg"] = tajikSurveyStrings;
surveyLocalization.localeNames["tg"] = "тоҷикӣ";
